module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"KL Logistik & Management Consulting e.U.","short_name":"KL","start_url":"/","lang":"de","background_color":"#111827","description":"40 Jahre Erfahrung in der Transport- und Logistikwelt. Davon 30 Jahre in Top-Managementpositionen im In- und Ausland. Langjährige Erfahrung im Konzern und in der Privatwirtschaft schärfen den Blick für die wirklich wichtigen Themen.","display":"browser","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"064d7a37dee4d44d78b25083730cccf5"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
